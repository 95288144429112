
import {Vue, Component, Prop, VModel, Emit, Inject, Watch} from 'vue-property-decorator'
import {getNoun} from '@/utils/plural.util'
import Modal from '@/components/entity/Modal'
import SwitchInput from '@/components/ui/SwitchInput'
import Input from '@/components/ui/Input'
import Checkbox from '@/components/ui/Checkbox'
import Button from '@/components/ui/Button'
import {ApiService} from '@/services/api.service'
import ErrorNotifierService from '@/services/errorNotifier.service'
import {TAPIEmployeeStatus} from '@/types/api.type'
import {validationMixin} from 'vuelidate'
import {maxValue, minValue, requiredIf} from 'vuelidate/lib/validators'

export type TModalLimitFormData = {
  status: TAPIEmployeeStatus
  limit: number | string
  unlimited: boolean
}

export type TModalSubmitData = {
  data: TModalLimitFormData
  employeeIds: string[]
}

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      limit: {
        requiredIf: requiredIf((form) => !form.unlimited),
        minValue: minValue(1),
        maxValue: maxValue(999999)
      }
    }
  },
  components: {
    Modal,
    SwitchInput,
    'InputText': Input.Text,
    Checkbox,
    Button
  }
})
export default class ModalLimit extends Vue {
  @VModel({type: Boolean})
  state!: boolean

  @Prop({type: Array, default: () => []})
  readonly employeeIds: string[]

  @Prop(Number)
  readonly quantity: number

  @Prop(String)
  readonly title: string

  @Prop([Object])
  readonly initials: TModalLimitFormData | null

  @Prop(Boolean)
  readonly limitWarn: boolean

  @Inject()
  readonly $api!: ApiService

  @Inject()
  readonly $error!: ErrorNotifierService

  form: TModalLimitFormData = {
    status: 'disabled',
    limit: '',
    unlimited: true
  }

  loading: boolean = false

  @Watch('form.status')
  onStatusChanged (newValue: TAPIEmployeeStatus) {
    if (newValue === 'disabled') {
      this.resetForm()
    }
  }

  @Watch('initials')
  onInitialsChanged (newValue: TModalLimitFormData | null) {
    if (newValue) {
      const extraOptions = {
        ...(Number(newValue.limit) === 0 ? {unlimited: true} : {}),
        ...(this.limitWarn ? {unlimited: false} : {})
      }
      this.form = {
        ...newValue,
        ...extraOptions
      }
    }
  }

  @Watch('warns')
  onWarnsChanged (newValue: keyof TModalLimitFormData) {
    this.$v.form[newValue]?.$touch()
  }

  get titleString () {
    if (this.title) {
      return this.title
    }

    const employeeText = getNoun(this.quantity, 'сотрудника', 'сотрудников', 'сотрудников')

    return `Управление лимитов для ${this.quantity} ${employeeText}`
  }

  get isFormDisabled () {
    const isServiceDisabled = this.form.status === 'disabled'
    const isQueued = this.form.status === 'processDisabled' || this.form.status === 'processEnabled'

    return isServiceDisabled || isQueued
  }

  resetForm () {
    this.form.status = 'disabled'
    this.form.limit = ''
    this.form.unlimited = true
    this.$v.form.$reset()
  }

  async onSubmitClick () {
    this.loading = true
    this.$v.$touch()

    if (this.$v.form.$invalid) {
      this.$v.form.$touch()
      this.loading = false

      return
    }

    try {
      const status: TAPIEmployeeStatus = this.form.status === 'disabled' ? 'processDisabled' : 'processEnabled'
      const data = this.employeeIds.map(item => ({
        ...this.form,
        status,
        limit: Number(this.form.limit),
        employeeID: item
      }))

      await this.$api.patchEmployeeSettings(data)

      this.form.status = status
      this.emitSubmit()
      this.resetForm()
      this.state = false
    } catch (error) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    } finally {
      this.loading = false
    }
  }

  onCancelClick () {
    this.resetForm()
    this.state = false
  }

  onServiceSwitched () {
    this.form.status = this.form.status === 'disabled' ? 'enabled' : 'disabled'

    if (this.form.status === 'disabled') {
      this.form.limit = 0
    }
  }

  onLimitInput (value: string) {
    if (Number(value) !== 0) {
      this.form.unlimited = false
    }
  }

  onUnlimitedInput (value: boolean) {
    if (value) {
      this.form.limit = ''
    }
  }

  @Emit('submit')
  emitSubmit () {
    return {
      data: {...this.form},
      employeeIds: this.employeeIds
    } as TModalSubmitData
  }

  @Emit('update:limitWarn')
  emitUpdateLimitWarn () {
    return false
  }
}
